import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <div className="level">
      <div className="level-item">
        &copy; 2026 Atlantic North Regional Convention - All rights reserved
      </div>
    </div>
    <div className="level">
      <div className="level-item">
      <Link to="/copyright">Copyright &amp; Usage</Link>
      </div>
      <div className="level-item">
        Contact &nbsp;<a href="mailto:anrc2026niagara@gmail.com">ANRC</a>
      </div>
    </div>
  </footer >
);

export default Footer;
